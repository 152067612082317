"use strict";
import {
    Modal,
    Sidenav
} from 'materialize-css';

var popup = document.getElementById('ConfirmModal');
var mob_nav = document.getElementById('slide-out');

function changeClass(add=true){
    var el = popup;
    var class_name = "grid-modal";
    if(add)
        el.classList.add(class_name);
    else
        el.classList.remove(class_name);
};
function addClass(){
    changeClass();
}
function removeClass(){
    changeClass(false);
};

document.addEventListener('DOMContentLoaded', function() {
var confirmModal = M.Modal.init(popup,{'dismissible': false,
'onOpenStart': addClass,
'onCloseEnd': removeClass
}).open();

});
